import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';
import ColoredLine from '../utils/ColoredLine';

const getAbout = graphql`
  query aboutPageImage {
    aboutPageImage: file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const About = ({ className }) => {
  const { aboutPageImage } = useStaticQuery(getAbout);
  return (
    <section className={className}>
      <article className="inspiration">
        <h2>Purpose</h2>
        <ColoredLine />
        <p>
          Thecodesmith.co exists to create educational resources on popular
          software topics, with the hope that these resources are able to aid
          understanding, and inspire new ideas in the minds of those who read or
          watch them.
        </p>
      </article>

      <div className="contentGrid">
        <div className="aboutHeading">
          <h2>Chief Code Craftsman</h2>
          <ColoredLine />
        </div>

        <article className="aboutImg">
          <Img
            fluid={aboutPageImage.childImageSharp.fluid}
            alt="picture of michael"
          />
        </article>
        <article className="aboutInfo">
          <p>
            Hey there! I'm Michael, the creator of this site. I've developed
            software professionally for 5 years now and wanted to start
            something that would push me outside of my comfort zone, and allow
            me to share the knowledge I've gained over time. I hope you enjoy
            the site, if you have any feedback (good or bad) I'd love to hear
            from you.
          </p>
          <AniLink fade to="/contact">
            <button type="button" className="btn-white aboutButton">
              Send Message
            </button>
          </AniLink>
        </article>
      </div>
    </section>
  );
};

export default styled(About)`
  font-size: 1.25rem;
  padding: 2rem 0;
  background: var(--mainEarth);
  color: white;
  min-height: 20em;
  text-align: center;

  article p {
    line-height: 1.7em;
  }

  .contentGrid,
  .inspiration {
    width: 95vw;
    margin: 0 auto;
    padding: 1rem;
    background: var(--darkEarth);
    border-radius: 0.25rem;
  }

  .inspiration {
    margin-bottom: 2rem;
  }

  .inspiration h2 {
    margin-bottom: 1rem;
  }

  .aboutImg {
    padding-bottom: 1.5rem;
  }

  .aboutImg img {
    border-radius: 0.25rem;
  }

  .aboutButton {
    margin-bottom: 1.5rem;
  }

  .aboutInfo h2 {
    padding-top: 2rem;
    font-size: 2rem;
  }

  @media (min-width: 576px) {
    font-size: 22px;

    .contentGrid,
    .inspiration {
      width: 90vw;
      padding: 2rem;
    }

    .aboutImg {
      margin: 0 5rem;
    }
  }

  @media (min-width: 768px) {
    .aboutImg {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  @media (min-width: 992px) {
    .contentGrid {
      display: grid;
      grid-template-rows: 20% 80%;
      grid-template-columns: 35% 65%;
      grid-template-areas:
        'heading heading'
        'image info';
    }

    .aboutHeading {
      grid-area: heading;
    }

    .aboutImg {
      grid-area: image;
      margin: 0 0.1rem;
      justify-self: center;
      min-width: 75%;
    }

    .aboutInfo {
      grid-area: info;
      text-align: left;
    }
  }
`;
