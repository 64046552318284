import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import About from '../components/About/About';

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`thecodesmith`, `about`, `michael`, `feliciano`]}
      />
      <About></About>
    </Layout>
  );
};

export default AboutPage;
